.ant-collapse {
  &.ant-collapse-no-footprint {

    margin: 0;
    .ant-collapse-header,
    > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box, {
      padding: 0;
    }
  }
}
