// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select-dropdown {
  border: 1px solid #e9e9e9;
}

.ant-select-selection:hover {
  border-color: $yellow;
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}

// Ensures proper height for "tag" type Select inputs
.ant-form-item-control .ant-form-item-children input {
  height: auto !important;
}
