/* Alpaca specific styles, Ant design adjustments */
@import 'style/core.scss';
@import 'style/themes.scss';
@import 'style/dashboard.scss';
@import 'style/onboarding.scss';
@import 'style/new-account.scss';
@import 'style/quick-trade.scss';
@import 'style/balances.scss';

/* Other vendor styles */
@import 'vendors/nprogress/nprogress.scss';
@import 'vendors/reactour/overrides.scss';


.chakra-skeleton {
  opacity: 0.2 !important;
}


svg {
  vertical-align: baseline;
}

.h-stack {
  display:flex;
  align-items: center;
}

.ant-dropdown-placement-bottomRight:not(.ant-dropdown-hidden) .ant-dropdown-menu {
  animation-name: fade;
  animation-fill-mode: both;
  -webkit-animation-name: fade;
  -webkit-animation-fill-mode: both;
  animation-duration: 300ms;
  -webkit-animation-duration: 300ms;
}

@keyframes fade {
  0% {
    transform: translate3d(0, 10px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  0% {
    -webkit-transform: translate3d(0, 10px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.badge-custom {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
}

.badge-custom.danger {
  color: rgb(237, 76, 120);
  background: rgba(237, 76, 120, 0.1);
}

.badge-custom.success {
  color: rgb(0, 201, 167);
  background: rgba(0, 201, 167, 0.1);
}

.orders-container, .portfolio_container, [class*="appsStyles__Container"]{
  padding: 1.4rem;
}
