@import '../../mixins';

/*  THEME STYLES */

body.menuShadow {
  .ant-layout-sider {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 200px -20px rgba(57,55,73,0);
  }
}

body.borderedCards {
  .card {
    border-top: 4px solid $success !important;
  }
}

body.themeLight {
  .ant-layout-sider-trigger {
    background: $gray-border;
    color: inherit;
  }

  .ant-layout-sider {
    background: $white;
    border-right: 1px solid $gray-border;
  }

  &:not(.menuCollapsed) {
    .ant-menu {
      color: $text;
      border-right: none;
    }
  }

  .ant-menu-item > a {
    color: $text;
    &:hover {
      color: $blue;
    }
  }

  .drawer-content {
    background: $white !important;
  }
}
