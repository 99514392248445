.underlineInput {
  border: 0;
  background: transparent;
  border-bottom: 1px solid black;
}

$inlineFormCutoff: 1200px;

@media (max-width: $inlineFormCutoff) {
  .qt-responsive-min-height {
    min-height: 140px;
  }
}

@media (min-width: $inlineFormCutoff) {
  .qt-responsive-min-height {
    min-height: 60px;
  }
}


.qt-box {
  margin-top: 30px;

  .form-group {
    .qt-btn-selected {
      // color: white;
      color: #303030;
      background-color: $yellow-light;
      font-family: Gilroy-Bold;
      border-color: gold;
    }

    .qt-btn-default:hover {
      background-color: $yellow-light;
    }

    .qt-input {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 18px;
      height: calc(1.5em + 2px);
    }

    .qt-quotes {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .btn-primary {
      max-width: 245px;
      width: 100%;
      font-size: 16px;
    }
  }
}