@import '../../mixins';

// New account form - supporting content
.limited-offer-box {
  background: #fff5c2;
  padding: 25px;
}

// "Onboarding" Wizard
.onboarding-active-step,
.onboarding-inactive-step {
  margin: 12px;
}

.onboarding-inactive-step {
  border: 1px solid $gray-darken;
  .step-card + .step-card {
    border-top: 1px solid $gray-darken;
  }
}
.onboarding-active-step {
  background: #fff;
  box-shadow: 2px 10px 20px 4px rgba(0, 0, 0, 0.1);
  .step-card + .step-card {
    border-top: 1px solid $gray-lighten;
  }
}

.onboarding-status-banner {
  margin: 12px;
}

.onboarding-gutter-row {
  .gutter-box {
    padding: 0px;
  }
}

.onboarding-double-buttons {
  width: 240px;
  margin: auto;
  button {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.onboarding {
  .ant-form-item-label label,
  .label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
  }
  .ant-calendar-picker {
    width: 100%;
  }
}

// Greeting (step 0)

.onboarding-greeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    display: block;
    height: 74px;
    margin-bottom: 50px;
  }

  h4 {
    margin-top: 30px;
    font-weight: 300;
  }
}
