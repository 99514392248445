// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN DROPDOWN */

.ant-dropdown {
  animation-duration: 0s !important;
}

.ant-dropdown-menu {
  border: 1px solid #e9e9e9;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: rem(13);
  padding: rem(8) rem(20);
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  padding: rem(8) rem(20);
  margin: rem(-8) rem(-20);
}

.ant-dropdown-menu-item > a.btn,
.ant-dropdown-menu-submenu-title > a.btn {
  margin: rem(-8) 0;
}

.ant-dropdown-menu-item-group-title {
  font-weight: bold;
  font-size: rem(13);
  padding: rem(12) rem(20);
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: $brown-white;
}

// Avatar icon (somehow got sized smaller - either an override or perhaps where it was used)
.topbar__avatar  {
  .anticon.anticon-user {
    font-size: 32px !important;
  }
}