// Import mixins from "core" module
@import '../../../mixins';

.ant-btn {
  color: $black;
  border-color: $gray-border-ui;

  &:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25) !important;
  color: $black;
  border-color: $gray-border-ui;
  transition: 0.3s;
  }

  &:active {
    background-color: $brown-white;
    border-color: $yellow-dark;
    color: $yellow-darker;
  }
}

.ant-btn--wrappable {
  min-height: 32px;
  height: auto;
  white-space: normal;
}

.ant-btn:hover, .ant-btn:focus {
  color: $black;
  border-color: $gray-border-ui;
}

.ant-btn:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25) !important;
  transition: 0.3s;
}

.ant-btn-primary {
  background-color: $yellow;
  border-color: $yellow;
  color: $black;

  &:hover, &:focus {
    background-color: $yellow-dark;
    border-color: $yellow-dark;
    color: $black;
  }

  &:active {
    background-color: $yellow-darker;
    border-color: $yellow-darker;
    color: $black;
  }
}

.ant-btn-secondary:hover {
  background-color: darken(#ffffff, 5%);
}

// Plain text links using <Button>
.ant-btn-link {
  border: 0;
  padding: 0;
  text-decoration: underline;
}

.ant-btn-brown {
  background-color: $brown-dark;
  color: $white;
}

// Plain text links using <Button>
.ant-btn-link {
  border: 0;
  padding: 0;
  text-decoration: underline;
  box-shadow: none;
  display: inline;
  line-height: 21px;
  color: $brown;
  @include transition-color();
  &:hover, &:focus {
    box-shadow: none !important;
    color: $text;
    background-color: darken(#ffffff, 5%);
  }
}

.ant-btn-brown {
  background-color: $brown-dark;
  color: $white;
}

.ant-btn-brown:hover, .ant-btn-brown:focus {
  background-color: $brown;
  border-color: $brown-dark;
  color: $white;
}

.ant-btn-green {
  background-color: $green;
  border-color: $green;
  color: $black;
}
.ant-btn-green:hover, .ant-btn-green:focus {
  background-color: $green-light;
  border-color: $green;
  color: $black;
}
