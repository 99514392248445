// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SLIDER */

.ant-slider-with-marks {
  margin-bottom: 19px;
}

.ant-layout-sider-children div {
  background-color: $soft-yellow;
}
