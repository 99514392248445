// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MENU */

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  font-size: 13px;
  box-shadow: none;
}

// Sider menu

body.menuShadow .ant-layout-sider {
  box-shadow: none !important;
  border-right: .0625rem solid rgba(231,234,243,.7);
}

.ant-menu > .ant-menu-item-divider {
  background-color: $black-lighter;
}

.ant-layout-sider-dark {
  background-color: $soft-yellow;
}

.ant-menu-item {
  background-color: $soft-yellow;
}

.menuLeft__navigation.ant-menu-dark .ant-menu-item:after {
  border-right: 3px solid $yellow !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: $brown-darkest;
  color: $yellow;
  > a {
    color: $yellow;
    .menuLeft__icon {
      color: $yellow !important;
    }
  }
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical {
  background-color: $soft-yellow;
}

.ant-layout-sider-trigger {
  background-color: none; // $black;
  background: none;

  i {
    color: $black-lightest;
  }
}

.ant-menu-item-label {
  display: block;
  height: 20px;
  line-height: 20px;
  opacity: 0.5;
  text-align: center;

  small {
    text-transform: uppercase;
  }
}

.menuLeft {
  &.ant-layout-sider-collapsed {
    .ant-menu-item-label {
      display: none;
    }
  }
}

///////
// For paper trading it takes on a different color
.menuLeft.product-paper.ant-layout-sider-dark {
  background-color: $white;
}
.menuLeft.product-paper {
  .ant-layout-sider-children div,
  ul.menuLeft__navigationPaper {
    background-color: $white;
  }
}
// They take on a different highlight/active color on the paper dashboard product context
.menuLeft.product-paper.ant-layout-sider-dark .ant-menu-item {
  background-color: $white;
}
.menuLeft.product-paper.ant-layout-sider-dark {
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: $white !important;
    color: $white !important;
    > a,
    > div {
      color: $white !important;
      .menuLeft__icon {
        color: $white !important;
      }
    }
    // Except brokerage items of course
    .brokerage-item {
      color: $yellow !important;
      .menuLeft__icon {
        color: $yellow !important;
      }
    }
  }
}

.menuLeft.product-paper .ant-layout-sider-children div, .menuLeft.product-paper ul.menuLeft__navigationPaper {
  background-color: transparent;
}

.menuLeft.product-paper.ant-layout-sider-dark .ant-menu-item {
  background-color: transparent;
}