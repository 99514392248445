// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-lighten-more-more;
}

.ant-input:focus {
  border-color: #ffd70d;
}

.ant-input:hover {
  border-color: #ffd70d;
}

.ant-input-number-disabled {
  background: $gray-lighten-more-more;
}

.ant-input-number-focused {
  border-color: #ffd70d;
  box-shadow: 0 0 0 2px #ffd70d2f;
}

.ant-input-number:hover {
  border-color: #ffd70d;
}
