// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN LAYOUT */

.ant-layout {
  min-height: 100vh;
  background: transparent;
}

.ant-layout-header {
  padding: 0;
}

.ant-layout-footer {
  padding: 0;
  background: transparent;
}

.ant-collapse {
  background: transparent;
}

.ant-layout-sider {
  transition: all .3s;
}

