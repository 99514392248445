@import '../../mixins';


.balances_container {
  padding-top: 0;
  overflow-y: scroll;
  min-height: 300px !important;

  .a {
    min-width: 40%;
    max-width: 40%;
    width: 40%;
  }
  .b {
    min-width: 30%;
    max-width: 30%;
    width: 30%;
    text-align: right;
  }
  .c {
    min-width: 30%;
    max-width: 30%;
    width: 30%;
    text-align: right;
  }
  .bal_subheading {
    font-size: 0.96rem;
    font-weight: 500;
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bal_subcontent {
    svg {
      margin-left: .4rem;
    }


    .bal_row {

      font-size: 0.96rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .bal_prefix {
        display: inline-block;
        min-width: 18px;
      }

      .small_prefix {
      }
    }


    .ant-row {
      padding: .6rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .ant-row:nth-of-type(odd) {
      background-color: var(--chakra-colors-gray-10);
    }

    // margin bottom on all ant rows except last
    .ant-row:not(:last-child) {
    }

    .bal_row div:nth-of-type(1) {
      white-space: nowrap;
      min-width: 40%;
      max-width: 40%;
      width: 40%;
    }
    .bal_row div:nth-of-type(2) {
      text-align: right;
      min-width: 30%;
      max-width: 30%;
      width: 30%;

    }
    .bal_row div:nth-of-type(3) {
      text-align: right;
      min-width: 30%;
      max-width: 30%;
      width: 30%;
    }


    .bal_row-header {
      font-size: 0.96rem;
      text-align: left;
    }
  }

  .bal_mobile {
    margin: 0em;

    .bal_row {
      margin: 1em 0em;
      font-size: 1em;
      text-align: right;

      .bal_prefix {
        display: inline-block;
        min-width: 1.1em;
      }

      .small_prefix {
        font-size: 0.8em;
        margin-left: 0.5em;
      }
    }

    .bal_row-header {
      font-size: 1em;
      text-align: left;
    }
  }

  .bal_txt_small {
    font-size: 0.75em;
    color: $text-lighten;
  }
}
