// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */


.ant-form-item-label label {
  text-align: left;
  font-size: 16px;
  color: #323232;
}

.ant-form-item-control {
  .ant-form-item-children {
    input {
      height: 40px;
    }
  }
}

.ant-form-item > .ant-form-item, .ant-form-item :not(.ant-form) > .ant-form-item {
  margin-top: -2px;
}

form .ant-select:only-child, form .ant-cascader-picker:only-child {
  display: inline-block;
}
